import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModTicketSale.html',
    selector: 'page-mod-ticket-sale',
    styleUrls: ['ModTicketSale.css', 'ModTicketSale.scss']
})
export class ModTicketSale {
    public title: string;
    public user: any;
    public mode: string;
    public phone: string;
    public card: any;
    public houses: any;
    public gamesSelected: any;
    public gTypes: any;
    public betLines: any;
    public selectAll: any;
    public numbers: string;
    public strAmount: number;
    public boxAmount: number;
    public mixAmount: number;
    public totalAmount: number;
    public isSelectedHouses: boolean;
    public borderDate: any;
    public ticket: any;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back',
            item: this.user
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public iwim: IwiModalService) {
        this.user = {};
        this.card = {};
        this.houses = {};
        this.gamesSelected = {};
        this.betLines = [];
        this.selectAll = {};
        this.numbers = '';
        this.totalAmount = 0;
        this.isSelectedHouses = false;
        this.aioChangeDetector = this.$aio_changeDetector;
        this.ticket = this.navParams.get('ticket');
        this.gTypes = ['early', 'late'];
        this.gTypes.map(gType => {
            this.gamesSelected[gType] = 0;
            this.selectAll[gType] = false;
            this.card[gType] = {
                expanded: false
            };
        })
        const Nassau = momenttz.tz(new Date(), "America/Nassau");
        const todayStrNassau = moment(Nassau).format('MM-DD-YYYY');
        this.borderDate = moment(todayStrNassau + ' 13:00:00');
        this.iwi.getHouses(() => {
            this.updateActiveHouses();
        });
        if (this.ticket) {
            //console.log(this.ticket)
            this.cloneBetLines();
        }
    }
    checkRoleForEditedUser(): any {
        var args = arguments;
        if (!this.user || !this.user.roles) {
            return false;
        }
        for (var a in args) {
            if (this.user.roles.indexOf(args[a].toUpperCase()) >= 0) {
                return true;
            }
        }
        //console.log($scope.r.userData.userRoles, arguments);
        return false;
    }
    startSale(): any {
        if (this.betLines.length <= 0) {
            this.iwiu.initAlert((this.iwi.checkRole('user')?
                'Buy': 'Sale') + ' Ticket Error', null, 'No Bet Lines in this ticket - nothing to buy/sale');
            return;
        }
        /*if (!this.user.phone || this.user.phone === '') {
            this.iwiu.initAlert('Buy Ticket Error', null, 'No Clients\' Phone number specified');
            return;
        }*/
        this.iwiu.initConfirmation(
            (this.iwi.checkRole('user')?
                'Buy': 'Sale') + ' Ticket',
            'Check total amount below before proceed',
            'Total ticket price <b>' + this.iwiu.currencyPipeTransform(this.totalAmount) + '<b/>',
            null, [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                    //no action just close alert
                }
            }, {
                text: this.iwi.checkRole('user')?
                'Buy': 'Sale',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    this.saleTicket();
                }
            }])
    }
    saleTicket(): any {
        this.iwiu.startSpinner('Processing..');
        let data: any = {
            betLines: this.betLines
        };
        if (this.user.phone && this.user.phone !== '') {
            data.phone = this.user.phone;
        }
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'money/ticket/sale',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    if (data.error) {
                        this.betLines = data.betLines;
                        /*this.betLines.forEach((betLine) => {
                            betLine.
                        });*/
                        this.iwiu.initAlert((this.iwi.checkRole('user')?
                            'Buy': 'Sale') + ' Ticket Failed', '', 'Ticket has validation errors. Please, check and fix then try again.');
                    } else {
                        this.user.phone = '';
                        this.betLines = [];
                        this.numbers = '';
                        this.strAmount = null;
                        this.boxAmount = null;
                        this.mixAmount = null;
                        this.gTypes.forEach((gType) => {
                            this.selectAll[gType] = false;
                            this.card[gType].expanded = false;
                            this.houses[gType].forEach((house) => {
                                house.selected = false;
                            });
                            this.isSelectedHouses = false;
                            this.gamesSelected[gType] = 0;
                        });
                        this.updateActiveHouses();
                        this.user = {};
                        //this.saleResults(data);
                        this.closeModal('sold');
                        this.iwim.initModTicketDetails(data, false);
                        //this.iwiu.initAlert('Sale Ticket', '', 'Ticket created successfully.');
                    }
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', (this.iwi.checkRole('user')?
                        'Buy': 'Sale') + ' Ticket', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    find(): any {
        this.iwiu.startSpinner('User search..');
        const data = {
            phone: this.user.phone
        };
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'account/find/user',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.user = data;
                    //this.iwiu.initAlert('User search', '', 'User found');
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Check-in error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    setSelectGame(game, gType): any {
        let indexToUpdate = this.houses[gType].findIndex(house => house.code === game.code);
        this.houses[gType][indexToUpdate].selected = !this.houses[gType][indexToUpdate].selected;
        this.houses[gType][indexToUpdate].selected? this.gamesSelected[gType]++: this.gamesSelected[gType]--;
        this.isSelectedHouses = false;
        this.gTypes.forEach((gType) => {
            this.selectAll[gType] = true;
            this.houses[gType].forEach((house) => {
                this.selectAll[gType] = this.selectAll[gType] && house.selected;
            });
            this.isSelectedHouses = this.isSelectedHouses || this.gamesSelected[gType] > 0;
        });
    }
    selectAllHouses($event, gType): any {
        if ($event !== undefined && $event !== null) {
            //$event.preventDefault();
            $event.stopPropagation();
        }
        this.selectAll[gType] = !this.selectAll[gType];
        this.isSelectedHouses = this.selectAll[gType];
        this.gamesSelected[gType] = this.selectAll[gType]? this.houses[gType].length: 0;
        this.houses[gType].forEach((house) => {
            house.selected = this.selectAll[gType];
        });
    }
    validateNumbers($event): any {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode($event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            $event.preventDefault();
        }
    }
    onPasteNumbers($event): any {
        $event.preventDefault();
        return false;
    }
    addBet(): any {
        if (!this.strAmount && !this.boxAmount && !this.mixAmount || (this.strAmount < 0 || this.boxAmount < 0 || this.mixAmount < 0)) return;
        const today = new Date();
        const todayStr = moment(today).format('YYYY-MM-DD')
        this.gTypes.forEach((gType) => {
            this.houses[gType].forEach((house) => {
                if (house.selected) {
                    let game = {
                        houseCode: house.code,
                        gameDate: todayStr,
                        numbers: this.numbers,
                    };
                    if (this.strAmount && this.strAmount > 0) {
                        this.betLines.push({
                            ...game,
                            iid: this.iwiu.getUniqueId(5),
                            type: 'STRAIGHT',
                            amount: this.strAmount,
                            betLine: this.numbers + ' ' + house.code + ' STR'
                        })
                    }
                    if (this.boxAmount && this.boxAmount > 0) {
                        this.betLines.push({
                            ...game,
                            iid: this.iwiu.getUniqueId(5),
                            type: 'BOX',
                            amount: this.boxAmount,
                            betLine: this.numbers + ' ' + house.code + ' BOX'
                        })
                    }
                    if (this.mixAmount && this.mixAmount > 0) {
                        this.betLines.push({
                            ...game,
                            iid: this.iwiu.getUniqueId(5),
                            type: 'BORLETTE',
                            amount: this.mixAmount,
                            betLine: this.numbers + ' ' + house.code + ' BORL'
                        })
                    }
                }
            });
        });
        //console.log(this.betLines);
        this.numbers = '';
        this.strAmount = null;
        this.boxAmount = null;
        this.mixAmount = null;
        this.calcTotalAmount();
    }
    deleteBet(betLine): any {
        this.betLines = this.betLines.filter((bet) => bet.iid !== betLine.iid);
        this.calcTotalAmount();
    }
    calcTotalAmount(): any {
        this.totalAmount = 0;
        this.betLines.forEach((bet) => this.totalAmount = this.totalAmount + bet.amount);
    }
    quickPick(pick): any {
        this.numbers = Math.random().toString(10).substring(2, 2 + pick);
    }
    saleResults111(data): any {
        let betLinesStr = '';
        let total = 0;
        data.betLines.forEach((betLine) => {
            betLinesStr = betLinesStr + '<br> <span>' + betLine.numbers + ' ' + betLine.houseCode + ' ' + betLine.type.substring(0, 3) + ' | ' + betLine.gameDate + ' | ' + this.iwiu.currencyPipeTransform(betLine.amount) + '</span>';
            total = total + betLine.amount;
        })
        this.iwiu.initConfirmation('Sale Ticket',
            'Ticket created successfully',
            '<br> Ticket number: ' + data.number +
            '<br>   Bet line   | Draw Date | Amount ' +
            betLinesStr +
            '<br> <br>' +
            '<br> <b> Total price: ' + this.iwiu.currencyPipeTransform(total) + '</b>',
            null, [{
                text: 'Print TBD',
                cssClass: 'secondary',
                handler: () => {
                    //no action just close alert
                }
            }, {
                text: 'Close',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    this.closeModal('update');
                }
            }])
    }
    updateActiveHouses(): any {
        this.houses['early'] = [];
        this.houses['late'] = [];
        //console.log('Games in Past');
        this.iwi.d.houses.forEach((house) => {
            if (!house.inPast) {
                if (this.borderDate.isAfter(house.datetime)) {
                    this.houses['early'].push(house);
                } else {
                    this.houses['late'].push(house);
                }
            } else {
                //console.log(house.code + ' - ' + house.time);
            }
        })
        //console.log(this.houses) 
        for (const [key, value] of Object.entries(this.houses)) {
            for (const [key1, value1] of Object.entries(this.houses[key])) {
                this.houses[key][key1].selected = false;
            }
        }
    }
    cloneBetLines(): any {
        const today = new Date();
        const todayStr = moment(today).format('YYYY-MM-DD')
        this.betLines = [];
        this.ticket.betLines.forEach((line) => {
            let game = {};
            this.betLines.push({
                iid: this.iwiu.getUniqueId(5),
                houseCode: line.houseCode,
                gameDate: todayStr,
                numbers: line.numbers,
                type: line.type,
                amount: line.amount,
                betLine: line.numbers + ' ' + line.houseCode + ' ' + line.type.substring(0, 3)
            })
        });
        //console.log(this.betLines);
        this.numbers = '';
        this.strAmount = null;
        this.boxAmount = null;
        this.mixAmount = null;
        this.calcTotalAmount();
    }
}