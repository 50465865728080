import {
    HttpClient,
    HttpParams
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    CurrencyPipe
} from '@angular/common';
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';
import {
    TicketPipe
} from './TicketPipe';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    ModalController,
    ToastController
} from '@ionic/angular';
import {
    AlertController
} from '@ionic/angular';
import {
    OverlayEventDetail
} from '@ionic/core';
import * as moment from 'moment';

import {
    PhotoViewer
} from '@ionic-native/photo-viewer/ngx';
import {
    Router
} from '@angular/router';
import {
    Observable,
    of
} from 'rxjs';

import {
    FormGroup,
    FormControl
} from '@angular/forms';

/*
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable()
export class IwiUtilService {

    private loadingController;
    private isCordovaApp = document.URL.indexOf('http://localhost') > -1 || document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
    public u: any = {
        spinner: false
    };
    public r: any = {
        qzPrint: {
            enabled: true
        },
    };
    public profilePendingChanges = true;
    constructor(
        private Apperyio: ApperyioHelperService,
        private http: HttpClient,
        private iab: InAppBrowser,
        private modalController: ModalController,
        private toastController: ToastController,
        private alertController: AlertController,
        private photoViewer: PhotoViewer,
        private router: Router,
        private currencyPipe: CurrencyPipe,
        private ticketPipe: TicketPipe
    ) {
        //touchstart
        document.addEventListener('click', (e: any) => {
            if (e.target && e.target.href && e.target.href.indexOf('https://') > -1) {
                //console.log("opening " + e.target.href);
                this.iab.create(e.target.href, '_system');
                e.preventDefault();
            }

            if (e.target && e.target.className && e.target.className.indexOf(['loading-wrapper', 'loading-content', 'loading-spinner']) > -1) {
                //console.log("opening " + e.target.href);
                //loading-wrapper
                this.stopSpinner();
                e.preventDefault();
            }
        });

        this.loadingController = this.Apperyio.getController("LoadingController");
        this.loadingController.create({
            message: 'Please wait...',
            spinner: 'crescent'
        });
    }

    navigateToPage(pageName: string, regetQuotes ? : boolean): void {
        //this.Apperyio.navigateTo(pageName, {});
        //this.navController.push(pageName, {});

        /*if (regetQuotes) {
            this.router.navigate([pageName, {
                path: 'random-' + Math.floor(Math.random() * 1000000)
            }]);
        } else {*/

        this.router.navigate([pageName]);

        //}
        // import { NavController } from '@ionic/angular';
        //this.navController.navigateBack([pageName]);
    }

    passChecker(password) {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        if (!password || password === '') {
            return 'red';
        } else if (strongRegex.test(password)) {
            return 'green';
        } else if (mediumRegex.test(password)) {
            return 'orange';
        } else {
            return 'red';
        }
    }

    startSpinner(message) {
        //console.log('---------------startSpinner-------------')
        this.trigger();
        this.u.spinner = true;
        this.loadingController.create({
            message: message || 'Please wait...',
            spinner: 'crescent'
        }).then(loading => {
            //console.log('---------------startSpinner present-------------')
            loading.present();
            setTimeout(() => {
                if (!this.u.spinner) {
                    this.stopSpinner();
                }
            }, 1500);
        });

    }

    /*async stopSpinner() {
        this.u.spinner = false;
        console.log('---------------stopSpinner-------------')
        while (await this.loadingController.getTop() !== undefined) {
            await this.loadingController.dismiss();
        }
    }*/
    async stopSpinner() {
        this.u.spinner = false;
        //try {
        if (await this.loadingController.getTop() !== undefined) {
            //console.log(this.loadingController.getTop())
            this.loadingController.dismiss();
        }
        //} catch (e) {}
        setTimeout(() => {
            if (this.u.spinner) {
                this.stopSpinner();
            }
        }, 1500);
    }

    async initAlert(header: string, subHeader: string, message: string, callback ? , backdropDismiss = true) {
        const alert = await this.alertController.create({
            header: header || 'Alert',
            subHeader: subHeader || '',
            message: message || 'Some unknown error.',
            backdropDismiss: backdropDismiss,
            buttons: [{
                text: 'Ok',
                handler: () => {
                    //console.log('Confirm Okay');
                    if (callback !== undefined && callback !== null) {
                        callback();
                    }
                }
            }]
        });

        await alert.present();
    }

    async initConfirmation(header: string, subHeader: string, message: string, callback ? , buttons ? ) {
        buttons = buttons === undefined ? [{
            text: 'Ok',
            handler: () => {
                //console.log('Confirm Okay');
                if (callback !== undefined) {
                    callback();
                }
            }
        }] : buttons;

        const alert = await this.alertController.create({
            header: header || 'Confirm',
            subHeader: subHeader || '',
            message: message || 'Please, confirm.',
            buttons: buttons
        });

        await alert.present();
    }

    async initNotes(header: string, subHeader: string, buttons, inputs) {
        const alert = await this.alertController.create({
            header: header || 'Note',
            subHeader: subHeader || 'Please, put your note below',
            buttons: buttons,
            inputs: inputs
        });

        await alert.present();
    }


    dateToString(date: any, pattern ? : string): string {
        //console.log('dateToString', date);
        if (date) {
            if (typeof date === 'string' && pattern !== undefined && typeof pattern === 'string') {
                date = moment(date, [pattern, 'YYYY-MM-DD']);
            } else if (typeof date === 'string' && pattern === undefined) {
                date = moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD']);
            }
            return moment(date).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    dateDiffInDays(a, b) {
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // a and b are javascript Date objects
        // Discard the time and time-zone information.
        if (typeof a === 'string') {
            a = new Date(a);
        }
        if (typeof b === 'string') {
            b = new Date(b);
        }
        //console.log(a, b);
        if (a && b) {
            var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        } else {
            return 0;
        }
    }

    checkSecureURL(url): boolean {
        const unsecureURL = ['/login', '/registration', '/info'];
        let secureURL = false;
        unsecureURL.forEach(unsecURL => {
            if (url.indexOf(unsecURL) > -1) {
                secureURL = true;
                //return true;
            }
        });
        return secureURL;
    }

    errorHandling(title, subTitle, error ? , callback ? ) {
        title = title ? title : 'Error';
        subTitle = subTitle || subTitle === ' ' ? subTitle : 'Server request failed';
        let message = 'API request failed';
        if (error && error.error) {
            message = error.error.message || error.error.detail;
        }

        if (error && (error.status !== 401)) {
            this.initAlert(title, subTitle, message);
        } else {
            if (callback !== undefined) {
                callback();
            }
        }
    }

    clickImage(image) {
        this.photoViewer.show(image);
    }

    openLinkOnNewTab($event, link) {
        $event.preventDefault();
        window.open(link, '_system');
    }

    newImageSrc(item) {
        //meal1[selectedMeal.mealTypeId]
        //https://res.cloudinary.com/iwi-images/image/upload/v1623475106/TEP/staging/06c4439dbcb601d0f8030b82f54ef61d/Meal/2021/June/12/10368.jpg
        //https://res.cloudinary.com/iwi-images/image/upload/g_center,q_auto:low,w_200,h_200/v1623475106/TEP/staging/06c4439dbcb601d0f8030b82f54ef61d/Meal/2021/June/12/10368.jpg
        if (!item || !item.imageURL) {
            console.log('Image Transformation Error: newImageSrc = ' + item, (item ? item.imageURL : ''));
            return '';
        }
        const STR_BEFORE_TRANSFORMATION = '/image/upload/';
        const TRANSFORMATION = '/g_center,q_auto:low,w_200,h_200';

        //this.iwi.r.isCordovaApp
        if (this.isCordovaApp) {
            return item.imageURL.substring(0, item.imageURL.indexOf(STR_BEFORE_TRANSFORMATION) +
                    STR_BEFORE_TRANSFORMATION.length) +
                TRANSFORMATION +
                item.imageURL.substring(item.imageURL.indexOf(STR_BEFORE_TRANSFORMATION) +
                    STR_BEFORE_TRANSFORMATION.length, item.imageURL.length);
        } else {
            return item.imageURL;
        }
    }

    async showToast(title, message, position ? , buttons ? , duration ? ) {
        this.trigger();
        /*{
                side: 'start',
                icon: 'star',
                text: 'Favorite',
                handler: () => {
                    console.log('Favorite clicked');
                }
            }, {
                text: 'Done',
                role: 'cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }*/
        const toast = await this.toastController.create({
            header: title,
            message: message,
            position: position || 'top',
            buttons: buttons || [{
                text: 'х',
                role: 'cancel',
                handler: () => {
                    //console.log('Cancel clicked');
                }
            }],
            duration: duration || 100000,
            //icon: 'notifications-outline',
            keyboardClose: true,
            color: 'secondary',
            cssClass: 'toast-transparent',
        });
        await toast.present();
    }

    async trigger(timeout ? : boolean) {
        // close alert
        try {
            const alertElement = await this.alertController.getTop();
            if (alertElement && timeout !== true) {
                alertElement.dismiss();
            }
        } catch (error) {}

        // close toaster
        try {
            const toastElement = await this.toastController.getTop();
            if (toastElement) {
                toastElement.dismiss();
            }
        } catch (error) {}
    }

    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    markFormGroupTouched(formGroup: FormGroup) {
        ( < any > Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    confirm(message ? : string): Observable < boolean > {
        const confirmation = window.confirm(message || 'Are you sure?');

        return of(confirmation);
    };

    toServerFromLocalToUtc(time) {
        const d = new Date(time);
        return (d.getUTCHours() < 10 ? '0' + d.getUTCHours() : d.getUTCHours()) + ':' + (d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes());
    }

    toServerFromLocalToUtcDate(date) {
        const d = new Date(date);
        return d.getUTCFullYear() + '-' + (d.getUTCMonth() + 1 < 10 ? '0' + (d.getUTCMonth() + 1) : d.getUTCMonth() + 1) + '-' + (d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate());
    }

    fromServerFromUtcToLocal(time, date ? ) {
        date = date || new Date().toISOString().slice(0, 10);
        var stillUtc = moment.utc(date + ' ' + time).toDate();
        return moment.utc(stillUtc).local().format();
    }

    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    getUniqueId(parts: number): string {
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

    validateTicketNumber(ticketNumber: string): boolean {
        if (!ticketNumber) return false;
        ticketNumber = ticketNumber.replaceAll('-', '');
        // Getting the string as a parameter
        // and typecasting it into an integer
        let myFunc = num => Number(num) * 2 > 9 ? Number(num) * 2 - 9 : Number(num) * 2;

        var intArr = Array.from(ticketNumber, myFunc);

        // Print the result array
        //console.log(intArr);

        const sum = intArr.reduce((partialSum, a) => partialSum + a, 0);
        //console.log(sum); 
        return sum % 10 === 0;
    }

    currencyPipeTransform(amount: string | number): string {
        return this.currencyPipe.transform(amount, 'USD', 'symbol', '1.2-2');
    }

    ticketPipeTransform(ticket: string): string {
        return this.ticketPipe.transform(ticket);
    }

}

/*
    Service class should be exported as ExportedClass
*/
export {
    IwiUtilService as ExportedClass
};