import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';

import {
    Injectable
} from '@angular/core';

import {
    CanDeactivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import {
    Observable,
    throwError
} from 'rxjs';
import {
    map
} from 'rxjs/operators';
import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';
/*
  See https://angular.io/guide/router for more info on guards.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

export interface ComponentCanDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(
        private Apperyio: ApperyioHelperService,
        private router: Router,
        private iwi: IwiService,
        private iwiu: IwiUtilService
    ) {}

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable <boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        console.log('canDeactivate - confirmation: ' + this.iwiu.profilePendingChanges);
        return this.iwiu.profilePendingChanges ?
            // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
            // when navigating away from your angular app, the browser will show a generic warning message
            // see http://stackoverflow.com/a/42207299/7307355
            this.iwiu.confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')
            : true;
    }

}

/*
    Guard class should be exported as ExportedClass
*/
export {
    PendingChangesGuard as ExportedClass
};