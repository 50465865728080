import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';

import {
    Injectable
} from '@angular/core';

import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import {
    Observable,
    throwError
} from 'rxjs';
import {
    map
} from 'rxjs/operators';
import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';
/*
  See https://angular.io/guide/router for more info on guards.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable({
    providedIn: 'root'
})
export class RefreshGuard implements CanActivate {
    constructor(
        private Apperyio: ApperyioHelperService,
        private router: Router,
        private iwi: IwiService,
        private iwiu: IwiUtilService
    ) {}

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {

        if (this.iwi.r && !this.iwi.r.loggedin) return false;

        this.iwi.refreshToken();
        if (this.iwi.sessionInterval === undefined || this.iwi.sessionInterval === null) {
            this.iwi.checkSession();
        }
        //console.log('refreshToken ', state);
        if (state && state.url === '/tabs/Home') {
            //this.iwi.getQuotes();
        }

        return true;
    }

}

/*
    Guard class should be exported as ExportedClass
*/
export {
    RefreshGuard as ExportedClass
};