import {
    HttpClient,
    HttpParams,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    Observable
} from 'rxjs';
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';
import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';
/*
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private Apperyio: ApperyioHelperService,
        private iwi: IwiService,
        private iwiu: IwiUtilService
    ) {}

    intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
        // add authorization header with jwt token if available
        const currentAuthToken = this.iwi.r.sessionToken;

        //console.log('JwtInterceptor', currentAuthToken);
        //if (currentAuthToken && !this.Iwiu.checkSecureURL(request.url)) {
        if (currentAuthToken) {
            const headers = {
                'Authorization': `Bearer ${currentAuthToken}`,
            };
            if (request.responseType === 'json') {
                headers['Content-Type'] = 'application/json';
            }
            /*if (request.responseType === 'arraybuffer') {
                console.log(request.responseType);
                headers['Content-Type'] = 'application/pdf';
            }*/
            request = request.clone({
                setHeaders: headers
            });
        }

        return next.handle(request);
    }

}

/*
    Service class should be exported as ExportedClass
*/
export {
    JwtInterceptor as ExportedClass
};