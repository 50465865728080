import {
    NgModule
} from '@angular/core';
import {
    IonicModule
} from '@ionic/angular';
import {
    ExportedClass as TicketPipe
} from './custom/TicketPipe';
import {
    ExportedClass as TypePipe
} from './custom/TypePipe';
import {
    ExportedClass as HiddenPipe
} from './custom/HiddenPipe';
import {
    ExportedClass as DoubleTapDirective
} from './custom/DoubleTapDirective';
@NgModule({
    declarations: [
        TicketPipe,
        TypePipe,
        HiddenPipe,
        DoubleTapDirective,
    ],
    imports: [IonicModule],
    exports: [
        TicketPipe,
        TypePipe,
        HiddenPipe,
        DoubleTapDirective,
    ]
})
export class PipesModule {}