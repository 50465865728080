import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModPhoneVerification.html',
    selector: 'page-mod-phone-verification',
    styleUrls: ['ModPhoneVerification.css', 'ModPhoneVerification.scss']
})
export class ModPhoneVerification {
    public title: string;
    public userData: any;
    public mode: string;
    public error: string;
    public pin: string;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || 'close',
            userData: this.userData
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService) {
        this.aioChangeDetector = this.$aio_changeDetector;
        this.title = this.navParams.get('title');
    }
    resendCode(): any {
        this.error = null;
        this.iwiu.startSpinner('Re-sending PIN..');
        this.iwi.http
            .post(
                this.iwi.r.API.sURL + 'activation/send', {}
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    //this.iwi.r.userData.verificationEmail - calculated field based on user type
                    this.iwiu.initAlert('Re-send PIN', null, 'Message with temporary PIN was re-sent on ' + this.iwi.r.userData.phone + '. Please, check Messages to find verification PIN.');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    //this.error = error && error.error ? error.error.message : 'Get Data failed.';
                    //this.error = (error && error.error && error.error.message) ? error.error.message : 'Re-send PIN failed.';
                    //console.log(error);
                    /*if (error === "Access is denied" || error.status === 401) {
                        this.closeModal();
                    }*/
                    this.iwiu.errorHandling('Error', 'Re-send error', error, () => {
                        this.closeModal();
                    });
                },
                () => {
                    //console.log("final");
                }
            );
    }
    verifyPin(pin): any {
        this.error = null;
        this.iwiu.startSpinner('Verifing..');
        this.iwi.http
            .post(
                this.iwi.r.API.sURL + 'activation/validate', {
                    code: pin
                }
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.iwi.r.userData = data.account;
                    this.iwi.r.sessionToken = data.token.access;
                    this.iwi.r.loggedin = true;
                    data.account.phone = data.account.phone.replace(/\s/g, '').replace(/[(-)]/g, '').replace('-', '');
                    localStorage.setItem('iwiUserData', JSON.stringify(data.account));
                    localStorage.setItem('iwiSessionToken', data.token.access);
                    //console.log('userData', this.iwi.r.userData);
                    //this.iwi.quotesNumber = Math.floor(Math.random() * this.iwi.quotes.length);
                    //console.log(this.iwiu.quotes.length, this.iwiu.quotesNumber)
                    //this.iwi.r.userData.verificationEmail = this.iwiu.getVerificationEmail(this.iwi.r.userData);
                    this.closeModal('valid');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    //this.error = (error && error.error && error.error.message) ? error.error.message : 'Verification failed.';
                    //console.log(error);
                    /*if (error === "Access is denied" || error.status === 401) {
                        this.closeModal();
                    }*/
                    //this.iwi.initAlert('Notification', 'Verification error', this.error);
                    this.iwiu.errorHandling('Error', 'Verification error', error, () => {
                        this.closeModal();
                    });
                },
                () => {
                    //console.log("final");
                }
            );
    }
    initChangeEmail(): any {
        //TODO
        this.iwi.r.changeEmail = this.userData.email;
        this.iwiu.initNotes('Change email', 'Enter new valid email', [{
            text: 'Cancel',
            handler: () => {
            }
        }, {
            text: 'Next',
            handler: (data) => {
                this.iwi.r.changeEmail = data.changeEmail;
                this.changeTry(() => {
                    //this.initRestoreCheck();
                });
            }
        }], [{
            name: 'changeEmail',
            value: this.iwi.r.changeEmail,
            type: 'email',
            placeholder: 'Enter email'
        }]);
    }
    changeTry(callback): any {
        this.error = null;
        this.iwiu.startSpinner('Changing email and re-sending Temp Code..');
        const data = {
            //TODO
            phone: this.iwi.r.changeEmail
        };
        this.iwi.http
            .post(
                this.iwi.r.API.sURL + 'activation/change/email',
                data,
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    /*this.iwiu.initConfirmation('Security', null, 'An email has been sent with activation token. Please login with new email and use it on validation form.', null, [{
                text: 'Ok',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    callback();
                    this.closeModal();
                    this.iwi.r.currentPlan = {};
                    this.iwi.r.loggedin = false;

                }
            }]);*/
                    /*if (callback !== undefined) {
                        callback();
                    }*/
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    //console.log(error);
                    if (error && error.error && error.error.code == 401 && error.error.message != "You have been inactive for over 15 minutes. Please log back in to continue") {
                        this.closeModal();
                        this.iwi.r.currentPlan = {};
                        this.iwi.r.loggedin = false;
                        //TODO
                        this.iwi.r.userData.phone = data.phone;
                        this.iwi.r.userData.username = data.phone;
                        localStorage.setItem('iwiUserData', JSON.stringify(this.iwi.r.userData));
                    } else {
                        const errorMsg = error.error? error.error.message: 'Change email failed. Please, check data and try again.';
                        this.iwiu.initAlert('Error', 'Change email error', errorMsg);
                    }
                },
                () => {
                    //console.log("final");
                }
            );
    }
}