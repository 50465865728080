import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import * as moment from 'moment';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModTopUp.html',
    selector: 'page-mod-top-up',
    styleUrls: ['ModTopUp.css', 'ModTopUp.scss']
})
export class ModTopUp {
    public title: string;
    public user: any;
    public mode: string;
    public phone: string;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back',
            item: this.user
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public tepm: IwiModalService) {
        this.user = {};
        this.aioChangeDetector = this.$aio_changeDetector;
        if (this.user?.phone) {
            this.phone = this.user.phone;
        }
    }
    find(): any {
        this.iwiu.startSpinner('User search..');
        let topupPhone = this.iwi.r.topUpPhone.replaceAll('-', '');
        topupPhone = topupPhone.replaceAll('(', '');
        topupPhone = topupPhone.replaceAll(')', '');
        topupPhone = topupPhone.replaceAll(' ', '');
        const data = {
            phone: topupPhone
        };
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'account/find/user',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.user = data;
                    //this.iwiu.initAlert('User search', '', 'User found');
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'User search error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    startTopUp(): any {
        this.iwi.r.topUpAmount = 1;
        this.iwiu.initNotes('User TopUp', 'Enter TopUp Amount', [{
            text: 'Cancel',
            handler: () => {}
        }, {
            text: 'TopUp',
            handler: (data) => {
                this.iwi.r.topUpAmount = data.topUpAmount;
                this.topUp();
            }
        }], [{
            name: 'topUpAmount',
            value: this.iwi.r.topUpAmount,
            type: 'number',
            placeholder: 'Enter amount'
        }]);
    }
    topUp(): any {
        this.iwiu.startSpinner('TopUp processing..');
        const data = {
            id: this.user.id,
            amount: this.iwi.r.topUpAmount
        };
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'money/topup',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.user.balance = +data.to.balance;
                    this.mode = 'update';
                    this.iwiu.initAlert('User TopUp', '',
                        'User\'s balance updated successfully. New balance ' + this.iwiu.currencyPipeTransform(data.to.balance));
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'TopUp error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
}