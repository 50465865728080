import { Pipe, PipeTransform } from '@angular/core';
/**
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'ticket',
})
export class TicketPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  /*transform(value: string, ...args) {
    return value.toLowerCase();
  }*/
  transform(value: string): string {
        if (!value || value === '') {
            return value;
        }
        
        const position1 = 4;
        const position2 = 8;
        const position3 = 12;
        const b = '-';
        const num = value;
        const numberStr = [num.slice(0, position1), b, num.slice(position1, position2), b, num.slice(position2, position3), b, num.slice(position3)].join('');

        return numberStr;
    }
}
/*
    Pipe class should be exported as ExportedClass
*/
export { TicketPipe as ExportedClass };