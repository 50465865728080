import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';

import {
    Injectable
} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import {
    Observable,
    throwError
} from 'rxjs';
import {
    map
} from 'rxjs/operators';
import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';
/*
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable({
    providedIn: 'root'
})
export class RefreshInterceptor implements HttpInterceptor {
    constructor(
        private Apperyio: ApperyioHelperService,
        private iwi: IwiService,
        private iwiu: IwiUtilService
    ) {}

    intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
        return next.handle(request)
            .pipe(map((event) => {

                if (event instanceof HttpResponse && request.url.indexOf('/refresh') < 0) {
                    if (event.status === 201 || event.status === 200) {
                        //console.log(request.headers.get("authorization").substring(7));
                        this.iwi.refreshToken();
                        if (this.iwi.sessionInterval === undefined || this.iwi.sessionInterval === null) {
                            this.iwi.checkSession();
                        }
                    }
                }
                return event;

            }));
    }
}

/*
    Service class should be exported as ExportedClass
*/
export {
    RefreshInterceptor as ExportedClass
};