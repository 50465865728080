import { Pipe, PipeTransform } from '@angular/core';
/**
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'TypePipe',
})

export class TypePipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  /*transform(value: string, ...args) {
    return value.toLowerCase();
  }*/
  transform(items: any[], filter: Object): any {
        if (!items || !filter || !(items instanceof Array)) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.type === filter);
    }
}
/*
    Pipe class should be exported as ExportedClass
*/
export { TypePipe as ExportedClass };