export const constants = {
    /**
     * Settings
     * @property production       - 
     */
    Settings: {
        "production": "false"
    },
    /**
     * Push Notification settings.
     * @property {boolean} badge       - If badge enabled for push
     * @property {boolean} alert       - If alert enabled for push
     * @property {boolean} sound       - If sound enabled for push
     * @property {string} senderID     - Sender Id for android devices
     * @property {string} url          - URL of Apperyio push rest service
     * @property {string} guid         - Project GUID
     */
    PushNotification: {
        ios: {
            badge: true,
            alert: true,
            sound: false,
            clearBadge: true
        },
        android: {
            senderID: null
        },
        url: "https://api.appery.io/rest/push/reg",
        guid: "d7bc6a33-ae49-4f40-b007-a046d45432fd"
    }
};
export const routes = {
    "Home": "home",
    "Profile": "profile",
    "Info": "info",
    "Start": "start",
    "Login": "login",
    "Register": "register",
    "Tabs": "tabs",
    "HomeTabs": "hometabs",
    "Welcome": "welcome",
    "Management": "management",
    "ManagementTabs": "managementtabs",
    "ManageClients": "manageclients",
    "History": "history",
    "ManageAgents": "manageagents",
    "ManagePayout": "managepayout",
    "ManageAppSettings": "manageappsettings",
    "ManageBetLimit": "managebetlimits",
    "Results": "results",
};
export const pushSettings = {
    appID: 'd7bc6a33-ae49-4f40-b007-a046d45432fd',
    baseUrl: 'https://api.appery.io/rest/push/reg',
    baseSendUrl: 'https://api.appery.io/rest/push/msg',
    initOptions: {
        ios: {
            alert: true,
            badge: true,
            sound: false,
            clearBadge: true
        },
        android: {
            senderID: ''
        }
    }
};
export const projectInfo = {
    guid: 'd7bc6a33-ae49-4f40-b007-a046d45432fd',
    name: 'iWin Games',
    description: 'Gaming App for Winners'
};
export const IGNORED_VALUE = Symbol.for("AIO_REST_IGNORED_VALUE");