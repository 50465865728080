import {
    Injectable
} from '@angular/core';
@Injectable()
export class ModalScreensService {
    private modalScreens: {
        [name: string]: any
    } = {};
    async getModalScreen(screenName: string) {
        if (!this.modalScreens[screenName]) {
            let modalImport;
            switch (screenName) {
                case "ModBalance":
                    modalImport = await
                    import (`../../ModBalance/ModBalance`);
                    break;
                case "ModTicketDetails":
                    modalImport = await
                    import (`../../ModTicketDetails/ModTicketDetails`);
                    break;
                case "ModWelcome":
                    modalImport = await
                    import (`../../ModWelcome/ModWelcome`);
                    break;
                case "ModTicketSale":
                    modalImport = await
                    import (`../../ModTicketSale/ModTicketSale`);
                    break;
                case "ModPhoneVerification":
                    modalImport = await
                    import (`../../ModPhoneVerification/ModPhoneVerification`);
                    break;
                case "ModCheckIn":
                    modalImport = await
                    import (`../../ModCheckIn/ModCheckIn`);
                    break;
                case "ModTopUp":
                    modalImport = await
                    import (`../../ModTopUp/ModTopUp`);
                    break;
                case "ModManageClient":
                    modalImport = await
                    import (`../../ModManageClient/ModManageClient`);
                    break;
                case "ModPayout":
                    modalImport = await
                    import (`../../ModPayout/ModPayout`);
                    break;
            }
            if (modalImport) {
                this.modalScreens[screenName] = modalImport[screenName];
            }
        }
        return this.modalScreens[screenName]
    }
};