import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import * as moment from 'moment';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModPayout.html',
    selector: 'page-mod-payout',
    styleUrls: ['ModPayout.css', 'ModPayout.scss']
})
export class ModPayout {
    public title: string;
    public ticketNumber: string;
    public mode: string;
    public ticket: any;
    public ticketNumbersError: string;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back'
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public tepm: IwiModalService) {
        this.ticketNumber = null;
        this.ticket = {};
        this.ticketNumbersError = null;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    find(): any {
        this.iwiu.startSpinner('Ticket search..');
        const data = {
            ticketNumber: this.ticketNumber.replaceAll('-', '')
        };
        this.iwi.http
            .get(
                this.iwi.r.API.URL + 'history/sale/' + this.ticketNumber.replaceAll('-', '')
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.ticket = data;
                    //this.iwiu.initAlert('User search', '', 'User found');
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.ticket = null;
                    this.iwiu.errorHandling('Ticket not found', 'Ticket search failed', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    startPayout(): any {
        if (this.ticketNumber.length <= 0) {
            this.iwiu.initAlert('Find Ticket Error', null, 'No Ticket found for Payout');
            return;
        }
        /*if (!this.user.phone || this.user.phone === '') {
            this.iwiu.initAlert('Buy Ticket Error', null, 'No Clients\' Phone number specified');
            return;
        }*/
        //const $filter = this.Apperyio.get("$filter");
        //const winningAmount = this.$filter('number')(this.ticket.winningAmount, '1.2-2');
        this.iwiu.initConfirmation(
            'Ticket Payout',
            'This operation can\'t be undone',
            'Ticket Payout amount: <b>' +
            this.iwiu.currencyPipeTransform(this.ticket.winningAmount) +
            '<b/>',
            null, [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                    //no action just close alert
                }
            }, {
                text: 'Payout',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    this.payout();
                }
            }])
    }
    payout(): any {
        this.iwiu.startSpinner('Payout processing..');
        const data = {
            id: this.ticket.id
        };
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'money/payout',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.ticketNumber = null;
                    this.mode = 'update';
                    this.iwiu.initAlert('Ticket Payout', '', 'Ticket successfully paid.', () => {
                        this.closeModal('update');
                    });
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Payout error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    validate(tNum): any {
        this.ticketNumbersError = null;
        if (tNum && tNum.length === 19) {
            //console.log(tNum);
            if (!this.iwiu.validateTicketNumber(tNum)) {
                this.ticketNumbersError = 'Mistake in Ticket numbers found, re-check your input';
            }
        }
    }
}