import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModWelcome.html',
    selector: 'page-mod-welcome',
    styleUrls: ['ModWelcome.css', 'ModWelcome.scss']
})
export class ModWelcome {
    public title: string;
    public text: string;
    public slideOpts: any;
    public didInit: boolean;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(): any {
        this.modalCtrl.dismiss();
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwiu: IwiUtilService) {
        this.slideOpts = {};
        this.didInit = false;
        this.aioChangeDetector = this.$aio_changeDetector;
        this.title = this.navParams.get('title');
        this.text = this.navParams.get('text');
    }
    goHome(): any {
        this.closeModal();
        this.iwiu.navigateToPage('hometabs/Home')
    }
    ngAfterViewInit(): any {
        //console.log(this.didInit);
        setTimeout(() => {
            //console.log('this.didInit = true;');
            this.didInit = true;
        }, 200);
    }
}