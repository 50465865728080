import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import * as moment from 'moment';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModCheckIn.html',
    selector: 'page-mod-check-in',
    styleUrls: ['ModCheckIn.css', 'ModCheckIn.scss']
})
export class ModCheckIn {
    public title: string;
    public mode: string;
    public phone: string;
    public pageNumber: number;
    public pageSize: number;
    public searchText: string;
    public loading: boolean;
    public reGetClients: Function;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back',
            //item: this.user
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public tepm: IwiModalService) {
        this.pageNumber = 0;
        this.pageSize = 10;
        this.searchText = '';
        this.loading = false;
        this.reGetClients = null;
        this.aioChangeDetector = this.$aio_changeDetector;
        this.iwi.r.agents = {
            content: []
        };
        this.getClients(0, this.pageSize, this.searchText);
    }
    startCheckIn(user): any {
        user.checkInAmount = +(user.balance.cash - user.balance.commission);
        this.iwi.startCheckIn(user, (data) => {
            //let agent = this.iwi.r.agents.content.filter((user) => user.id === data.id);
            //agent = data;
            let indexToUpdate = this.iwi.r.agents.content.findIndex(user => user.id === data.id);
            this.iwi.r.agents.content[indexToUpdate] = data;
        });
    }
    getClients(pageNumber?, pageSize?, search?): any {
        this.pageNumber = pageNumber? pageNumber: 0;
        this.pageSize = pageSize? pageSize: 15;
        this.searchText = search? search: this.searchText;
        const data = {
            page: this.pageNumber,
            size: this.pageSize,
            text: this.searchText
        }
        if (this.pageNumber > this.iwi.r.clients?.totalPages - 1 || this.pageNumber < 0) {
            return;
        }
        if (this.loading === false) {
            this.loading = true;
            setTimeout(() => {
                //this.iwiu.startSpinner('Loading..');
                this.iwi.http
                    .post(
                        this.iwi.r.API.URL + 'check-in/find',
                        data
                    )
                    .subscribe(
                        (data: any) => {
                            //this.iwiu.stopSpinner();
                            this.iwi.r.agents = data;
                            this.loading = false;
                            if (this.reGetClients !== null) {
                                this.reGetClients();
                            }
                            setTimeout(function() {
                                if (document.getElementById("search")) {
                                    document.getElementById("search").getElementsByTagName('input')[0].focus();
                                }
                            }, 200);
                        },
                        (error: any) => {
                            this.loading = false;
                            //this.iwiu.stopSpinner();
                            this.iwiu.errorHandling('Error', 'Get data error', error)
                        },
                    );
            }, 500);
        } else {
            //this.pageNumber, this.pageSize, this.searchText
            this.reGetClients = () => {
                this.reGetClients = null;
                this.getClients(this.pageNumber, this.pageSize, this.searchText);
            };
        }
    }
}