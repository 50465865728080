import {
    HttpClient,
    HttpParams
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    ModalController
} from '@ionic/angular';
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';
import {
    Observable
} from 'rxjs';
import {
    Router
} from '@angular/router';

import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';

import * as moment from 'moment';
import _ from 'lodash';

@Injectable()
export class IwiModalService {

    constructor(
        private Apperyio: ApperyioHelperService,
        public http: HttpClient,
        private iwi: IwiService,
        private iwiu: IwiUtilService,
        private router: Router
    ) {

    }

    initModWelcome() {

        this.iwi.refreshToken();
        this.Apperyio.showModal("ModWelcome", {
                componentProps: {},
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "welcome-modal",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    if (dataReturned.data !== undefined) {

                    }
                });
            });
    }

    initModHistory(callback ? ) {

        this.iwi.refreshToken();

        this.Apperyio.showModal("ModHistory", {
                componentProps: {},
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                });
            });
    }

    getClientDetails(id, $event ? , callback ? , errorCallback ? ) {
        this.iwiu.startSpinner('Loading..');
        this.iwi.http
            .get(
                this.iwi.r.API.URL + 'account/management/' + id
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();

                    this.initModManageClient(data, $event, callback);
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    errorCallback && errorCallback();
                },
            );

    }


    initModManageClient(item, $event ? , callback ? ) {
        this.iwi.refreshToken();
        if ($event !== undefined && $event !== null) {
            $event.preventDefault();
        }

        let itemCopy = _.cloneDeep(item);

        this.Apperyio.showModal("ModManageClient", {
                componentProps: {
                    item: itemCopy
                },
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    callback && callback();
                    /*if (dataReturned.data !== undefined) {
                        if (dataReturned.data.mode === 'back') {

                        } else {
                            callback && callback();
                        }
                    }*/
                });
            });
    }

    initModTopUp() {
        this.iwi.refreshToken();

        this.Apperyio.showModal("ModTopUp", {
                componentProps: {},
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                });
            });
    }

    initModCheckin() {
        this.iwi.refreshToken();

        this.Apperyio.showModal("ModCheckIn", {
                componentProps: {},
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    if (dataReturned.data !== undefined) {}
                });
            });
    }

    initModPayout() {
        this.iwi.refreshToken();

        this.Apperyio.showModal("ModPayout", {
                componentProps: {},
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    if (dataReturned.data !== undefined) {}
                });
            });
    }

    getBalance(callback ? ) {
        this.iwiu.startSpinner('Loading..');
        this.iwi.http
            .get(
                this.iwi.r.API.URL + 'balance'
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();

                    this.iwi.r.userData.balance = {
                        balance: data.balance,
                        bonus: data.bonus,
                        cash: data.cash,
                        commission: data.commission,
                        lastCheckIn: data.lastCheckIn
                    };
                    //this.iwim.initModBalance(data, $event, () => this.getClients(this.pageNumber, this.pageSize));
                    this.initModBalance(this.iwi.r.userData);

                    //this.loading = false;
                    callback && callback();
                },
                (error: any) => {
                    //this.loading = false;
                    callback && callback();

                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Get Balance error', error)
                },
            );
    }

    initModBalance(user) {
        this.iwi.refreshToken();

        this.Apperyio.showModal("ModBalance", {
                componentProps: {
                    user: user
                },
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    if (dataReturned.data !== undefined) {}
                });
            });
    }

    initModTicketSale(item) {
        this.iwi.refreshToken();

        this.Apperyio.showModal("ModTicketSale", {
                componentProps: {
                    ticket: item,
                },
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    if (dataReturned.data !== undefined) {}
                });
            });
    }

    getTicketDetails(ticketNumber, callback ? ) {
        this.iwiu.startSpinner('Ticket search..');

        this.iwi.http
            .get(
                this.iwi.r.API.URL + 'history/sale/' + ticketNumber.replaceAll('-', '')
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();

                    this.initModTicketDetails(data, callback);
                    //this.iwiu.initAlert('User search', '', 'User found');
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();

                    callback && callback();
                    this.iwiu.errorHandling('Error', 'Ticket search error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }

    initModTicketDetails(item, showWinning = true, callback ? ) {
        this.iwi.refreshToken();

        this.Apperyio.showModal("ModTicketDetails", {
                componentProps: {
                    item: item,
                    showWinning: showWinning
                },
                showBackdrop: false,
                backdropDismiss: false,
                cssClass: "",
                animated: true,
                keyboardClose: true
            })
            .then(modal => {
                modal.present();
                modal.onDidDismiss().then((dataReturned) => {
                    this.iwi.refreshToken();
                    callback && callback();
                    if (dataReturned.data !== undefined) {
                        if (dataReturned.data.mode === 'clone') {
                            this.initModTicketSale(dataReturned.data.item);
                        } else {
                            //callback && callback();
                        }

                    }
                });
            });
    }

}

/*
    Service class should be exported as ExportedClass
*/
export {
    IwiModalService as ExportedClass
};