import {
    BrowserModule
} from '@angular/platform-browser';
import {
    BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
    FormsModule
} from '@angular/forms';
import {
    HttpClientModule
} from '@angular/common/http';
import {
    IonicModule
} from '@ionic/angular';
import {
    IonicStorageModule
} from '@ionic/storage';
import {
    ApperyioModule
} from './scripts/apperyio/apperyio.module';
import {
    ApperyioDeclarablesModule
} from './scripts/apperyio/declarables/apperyio.declarables.module';
import {
    PipesModule
} from './scripts/pipes.module';
import {
    DirectivesModule
} from './scripts/directives.module';
import {
    ComponentsModule
} from './scripts/components.module';
import {
    CustomComponentsModule
} from './scripts/custom-components.module';
import {
    CustomModulesModule
} from './scripts/custom-modules.module';
import {
    Sanitizer
} from '@angular/core';
import {
    NgDompurifySanitizer
} from '@tinkoff/ng-dompurify';
import {
    createTranslateLoader
} from './scripts/apperyio/translate_module';
import {
    TranslateModule
} from '@ngx-translate/core';
import {
    TranslateLoader
} from '@ngx-translate/core';
import {
    HttpClient
} from '@angular/common/http';
import {
    app
} from './app';
import {
    AppRoutingModule
} from './app-routing.module';
import {
    ModBalance
} from './ModBalance/ModBalance';
import {
    ModTicketDetails
} from './ModTicketDetails/ModTicketDetails';
import {
    ModWelcome
} from './ModWelcome/ModWelcome';
import {
    ModTicketSale
} from './ModTicketSale/ModTicketSale';
import {
    ModPhoneVerification
} from './ModPhoneVerification/ModPhoneVerification';
import {
    ModCheckIn
} from './ModCheckIn/ModCheckIn';
import {
    ModTopUp
} from './ModTopUp/ModTopUp';
import {
    ModManageClient
} from './ModManageClient/ModManageClient';
import {
    ModPayout
} from './ModPayout/ModPayout';
import {
    ExportedClass as IwiService
} from './scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from './scripts/custom/IwiUtilService';
import {
    ExportedClass as JwtInterceptor
} from './scripts/custom/JwtInterceptor';
import {
    ExportedClass as ErrorInterceptor
} from './scripts/custom/ErrorInterceptor';
import {
    ExportedClass as RefreshInterceptor
} from './scripts/custom/RefreshInterceptor';
import {
    ExportedClass as IwiModalService
} from './scripts/custom/IwiModalService';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    Media
} from '@ionic-native/media/ngx';
import {
    Push
} from '@ionic-native/push/ngx';
import {
    File
} from '@ionic-native/file/ngx';
import {
    WebView
} from '@ionic-native/ionic-webview/ngx';
import {
    Device
} from '@ionic-native/device/ngx';
import {
    SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
    StatusBar
} from '@ionic-native/status-bar/ngx';
import {
    Keyboard
} from '@ionic-native/keyboard/ngx';
//import { SimpleMaskModule, SimpleMaskPipe  } from 'ngx-ion-simple-mask';
import {
    SimpleMaskModule,
    SimpleMaskDirective,
    SimpleMaskPipe
} from 'ngx-ion-simple-mask';
import {
    TicketPipe
} from './scripts/custom/TicketPipe';
import {
    CurrencyPipe
} from '@angular/common';
import {
    APP_INITIALIZER,
    ErrorHandler,
    NgModule
} from "@angular/core";
import {
    Router
} from "@angular/router";
import {
    environment
} from '../environments/environment';
//import { Integrations } from "@sentry/tracing";
import {
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
    PhotoViewer
} from '@ionic-native/photo-viewer/ngx';
import {
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig
} from '@angular/platform-browser';
import {
    NgCalendarModule
} from 'ionic2-calendar';
import {
    PdfViewerModule
} from 'ng2-pdf-viewer';
import {
    Camera
} from '@ionic-native/camera/ngx';
( < any > NgDompurifySanitizer.prototype)._sanitize_fn = NgDompurifySanitizer.prototype.sanitize;
NgDompurifySanitizer.prototype.sanitize = function(...args) {
    let value: any = args[1];
    if (value && value.hasOwnProperty("changingThisBreaksApplicationSecurity")) {
        args[1] = value.changingThisBreaksApplicationSecurity
    }
    return this._sanitize_fn(...args);
}
var getIonicModuleConfig, getIonicStorageModuleConfig;
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = {
        'press': {
            time: 500
        },
        /* default: 251 ms */
        'pinch': {
            enable: false
        },
        'rotate': {
            enable: false
        },
    };
}
@NgModule({
    declarations: [
        app, ModBalance, ModTicketDetails, ModWelcome, ModTicketSale, ModPhoneVerification, ModCheckIn, ModTopUp, ModManageClient, ModPayout
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        IonicModule.forRoot((typeof getIonicModuleConfig === "function")? getIonicModuleConfig(): undefined),
        HttpClientModule,
        ApperyioModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        ApperyioDeclarablesModule,
        CustomComponentsModule,
        CustomModulesModule,
        IonicStorageModule.forRoot((typeof getIonicStorageModuleConfig === "function")? getIonicStorageModuleConfig(): undefined), NgCalendarModule,
        PdfViewerModule,
        SimpleMaskModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    bootstrap: [
        app
    ],
    entryComponents: [
        //app
        ModBalance, ModTicketDetails, ModWelcome, ModTicketSale, ModPhoneVerification, ModCheckIn, ModTopUp, ModManageClient, ModPayout
    ],
    providers: [
        StatusBar,
        SplashScreen,
        WebView,
        Device,
        Keyboard,
        {
            provide: Sanitizer,
            useClass: NgDompurifySanitizer,
        },
        InAppBrowser,
        Camera,
        Media,
        Push,
        File,
        IwiService,
        IwiUtilService,
        JwtInterceptor,
        ErrorInterceptor,
        RefreshInterceptor,
        IwiModalService,
        CurrencyPipe,
        TicketPipe,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: CustomHammerConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshInterceptor,
            multi: true
        },
        PhotoViewer,
        /*{
              provide: ErrorHandler,
              useValue: Sentry.createErrorHandler({
                showDialog: false,
              }),
        },
        {
              provide: Sentry.TraceService,
              deps: [Router],
        },
        {
              provide: APP_INITIALIZER,
              useFactory: () => () => {},
              deps: [Sentry.TraceService],
              multi: true,
        }*/
    ]
})
export class AppModule {}