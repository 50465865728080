// Very important content
import {
    enableProdMode
} from '@angular/core';
import {
    platformBrowserDynamic
} from '@angular/platform-browser-dynamic';
import {
    AppModule
} from './app/app.module';
import {
    environment
} from './environments/environment';
if (environment.production) {
    enableProdMode();
}
import { CalendarComponent } from 'ionic2-calendar/calendar';
import { MonthViewComponent } from 'ionic2-calendar/monthview';
import { WeekViewComponent } from 'ionic2-calendar/weekview';
import { DayViewComponent } from 'ionic2-calendar/dayview';
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));