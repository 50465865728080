import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from './scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from './scripts/apperyio/apperyio_mapping_helper';
import {
    MenuController
} from '@ionic/angular';
import {
    NavController
} from '@ionic/angular';
import {
    Platform
} from '@ionic/angular';
import {
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
    StatusBar
} from '@ionic-native/status-bar/ngx';
import {
    Observable
} from 'rxjs';
import {
    ExportedClass as IwiService
} from './scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from './scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from './scripts/custom/IwiModalService';
import {
    NavigationStart
} from '@angular/router';
import {
    $aio_empty_object
} from './scripts/interfaces';
@Component({
    templateUrl: 'app.html',
    selector: 'app-root',
    styleUrls: ['app.css', 'app.scss']
})
export class app {
    public deviceType: string = 'web-browser';
    public rootScopeInitialVars: any;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public platform: Platform, public statusBar: StatusBar, public splashScreen: SplashScreen, private menuCtrl: MenuController, public iwi: IwiService, public iwiu: IwiUtilService, public iwim: IwiModalService, public router: Router) {
        this.aioChangeDetector = this.$aio_changeDetector;
        this.deviceType = window.cordova? 'mobile': 'web-browser';
        // do not remove this code unless you know what you do
        this.platform.ready().then(() => {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            console.log('setupInitialConfiguration');
            this.iwi.setupInitialConfiguration();
            //this.push.init();
        });
        // close all popovers on back navigation, if open.
        this.router.events.subscribe((event: any): void => {
            if (event instanceof NavigationStart) { //@angular/router
                if (event.navigationTrigger === 'popstate') {
                    this.iwi.trigger();
                }
            }
        });
        this.Apperyio.push.init();
    }
    initModMealPlan(): any {
        /*if (this.iwi.r.currentPlan.completed !== true || this.iwi.r.currentPlan.id === null || this.iwi.r.currentPlan.id === undefined) {
    //this.iwil.initModMealPlan({}, 'setup', 'Meal Plan setup', this.iwi.r.currentPlan);
    //this.iwil.initModMealPlan(this.iwi.r.currentPlan, 'setup', 'Meal Plan setup', this.iwi.r.currentPlan);
} else {
    //this.iwil.initModMealPlan({}, '', 'Meal Plan edit', this.iwi.r.currentPlan)
}*/
    }
    async menulistClick__j_16(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.Apperyio.getController("MenuController").close();
    }
}