import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import * as moment from 'moment';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModBalance.html',
    selector: 'page-mod-balance',
    styleUrls: ['ModBalance.css', 'ModBalance.scss']
})
export class ModBalance {
    public title: string;
    public user: any;
    public mode: string;
    public card: any;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back',
            item: this.user
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public tepm: IwiModalService) {
        this.card = {
            perm: {
                expanded: false
            },
            docs: {
                expanded: false
            },
            surveys: {
                expanded: false
            },
            agent: {
                expanded: false
            }
        };
        this.aioChangeDetector = this.$aio_changeDetector;
        this.user = this.navParams.get('user');
        //console.log(this.user);
        if (this.checkRoleForEditedUser('agent')) {
            this.user.checkInAmount = this.user.balance.cash - this.user.balance.commission;
        }
    }
    checkRoleForEditedUser(arg?): any {
        var args = arguments;
        if (!this.user || !this.user.roles) {
            return false;
        }
        for (var a in args) {
            if (this.user.roles.indexOf(args[a].toUpperCase()) >= 0) {
                return true;
            }
        }
        //console.log($scope.r.userData.userRoles, arguments);
        return false;
    }
}