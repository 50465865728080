import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';

import {
    Injectable
} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import {
    Observable,
    throwError
} from 'rxjs';
import {
    catchError
} from 'rxjs/operators';
import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';
//import * as Sentry from '@sentry/browser'

/*
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    //public scope = new Sentry.Scope();
    
    constructor(
        private Apperyio: ApperyioHelperService,
        private iwi: IwiService,
        private iwiu: IwiUtilService
    ) {
        //this.scope.setTag("section", "http");
    }

    intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
        return next.handle(request)
            .pipe(catchError(err => {
                const error = err || 'Server Error.';
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.iwi.logout().toPromise().then(() => {
                        //location.reload();
                        //this.iwiu.initAlert('Unauthorized', null, err.error.message)
                        this.iwiu.initAlert('Unauthorized', null, err.message || err.detail)
                    });
                } else {
                    //console.log(err);
                    if (err) {
                        var errorMsg = err.error && err.error.message || 'HTTP Error';
                        //Sentry.captureException(new Error(errorMsg), this.scope);
                    }
                }

                return throwError(error);
            }));
    }
}

/*
    Service class should be exported as ExportedClass
*/
export {
    ErrorInterceptor as ExportedClass
};