import {
    Injectable,
    NgZone
} from '@angular/core';
import {
    checkAvailability
} from '@ionic-native/core';
import {
    Push,
    PushObject,
    PushOptions
} from '@ionic-native/push/ngx';
import {
    Platform,
    ModalController,
    ToastController
} from '@ionic/angular';
import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpErrorResponse
} from '@angular/common/http';
import {
    catchError
} from 'rxjs/operators';
import {
    throwError
} from 'rxjs';
import {
    Device
} from '@ionic-native/device/ngx';
import {
    pushSettings
} from '../constants';
const NOTIFICATION = "notification";
const ERROR = "error";
const REGISTER = "register";
const INIT = "init";
const TEST = "test";
const END_POINT_URL = 'https://api.withmewhen.com'; //pushSettings.baseUrl;
const APP_ID = pushSettings.appID;


@Injectable()
export class ApperyioPushHelperService {
    private deviceToken: string;
    private pushObject: PushObject;
    private uuid: string;
    private httpOptions;
    private registerUrl = "";
    private updateUrl = "";
    private unregisterUrl = "";
    private callbacks = {
        [NOTIFICATION]: [],
        [ERROR]: [],
        [REGISTER]: [],
        [INIT]: []
    }
    constructor(
        private push: Push,
        private http: HttpClient,
        private device: Device,
        private platform: Platform,
        private ngZone: NgZone,
        private toastController: ToastController,
    ) {
        const commonHttpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.setCommonHttpOptions(commonHttpOptions);
    }
    setCommonHttpOptions(commonHttpOptions) {
        this.httpOptions = commonHttpOptions;
    }
    setRegisterUrl(url ? ) {
        this.registerUrl = url;
    }
    setUpdateUrl(url ? ) {
        this.updateUrl = url;
    }
    setUnregisterUrl(url ? ) {
        this.unregisterUrl = url;
    }
    getRegisterUrl() {
        return this.registerUrl ? this.registerUrl : END_POINT_URL;
    }
    getUpdateUrl() {
        return this.updateUrl ? this.updateUrl : END_POINT_URL + '/' + this.uuid;
    }
    getUnregisterUrl() {
        return this.unregisterUrl ? this.unregisterUrl : END_POINT_URL + '/' + this.uuid;
    }
    private _execCallbacks(eventName: string, ...args) {
        let callbacks = this.callbacks[eventName];
        callbacks && callbacks.length && callbacks.forEach && this.ngZone.run(() => {
            callbacks.forEach(cb => cb.apply(null, args));
        });
    }
    private _getTimeZone() {
        let offset = new Date().getTimezoneOffset();
        let hr = offset / (-60);
        let min = -offset - hr * 60;
        let tmin = '' + min;
        let timezone = 'UTC' + (hr > 0 ? '+' + hr : hr) + ':' + (tmin.length > 1 ? tmin : '0' + tmin);
        return timezone;
    }
    on(eventName, callback): ApperyioPushHelperService {
        if (this.callbacks[eventName]) {
            this.callbacks[eventName].push(callback);
        }
        return this;
    }
    off(eventName, callback ? ): ApperyioPushHelperService {
        if (this.callbacks[eventName]) {
            if (callback === undefined) {
                this.callbacks[eventName] = [];
                return this;
            }
            const index = this.callbacks[eventName].indexOf(callback);
            if (index !== -1) {
                this.callbacks[eventName].splice(index, 1);
            }
        }
        return this;
    }
    init(options ? , registerOptions ? ) {
        if (this.pushObject) return;
        this.platform.ready().then(() => {
            const isPushPluginEnabled = checkAvailability(this.push);
            if (isPushPluginEnabled && !( < any > isPushPluginEnabled).error) {
                this.push.hasPermission()
                    .then((res: any) => {
                        if (res.isEnabled) {
                            if (this.pushObject) return;
                            console.log('We have permission to send push notifications');
                            const initOpts: PushOptions = pushSettings.initOptions;
                            let initOptions = {};
                            if (!options) {
                                options = {};
                            }
                            ["ios", "android", "windows", "browser"].forEach(name => {
                                if (initOpts[name] || options[name]) {
                                    initOptions[name] = {...initOpts[name],
                                        ...options[name]
                                    }
                                }
                            });
                            this.pushObject = this.push.init(initOptions);
                            this.pushObject.on('notification').subscribe(async(notification: any) => {
                                console.log('PUSH service: notification');
                                //alert(notification.message + ' !');
                                const toast = await this.toastController.create({
                                    header: notification.title,
                                    message: notification.message,
                                    position: 'top',
                                    buttons: [{
                                        text: 'х',
                                        role: 'cancel',
                                        handler: () => {
                                            //console.log('Cancel clicked');
                                        }
                                    }],
                                    duration: 100000,
                                    //icon: 'notifications-outline',
                                    keyboardClose: true,
                                    color: 'secondary',
                                    cssClass: 'toast-transparent',
                                });
                                await toast.present();

                                this._execCallbacks(NOTIFICATION, notification);
                            });
                            this.pushObject.on('registration').subscribe((registration: any) => {
                                console.log('Device registered', registration);
                                //alert('Device registered !!!');
                                this.deviceToken = registration.registrationId;
                                this._execCallbacks(INIT, registration);
                                this.register(registerOptions);
                            });
                            this.pushObject.on('error').subscribe(error => {
                                console.error('Error with Push plugin', error)
                            });
                        } else {
                            console.log('We do not have permission to send push notifications');
                        }
                    });
            }
        });
    }
    register(options: any = {}) {
        let device = this.device;
        if (device) {
            let deviceType = (device.platform === 'iOS') ? 'I' : 'A';
            this._setUuid(options.deviceID);
            this.uuid = (window as any).device.uuid;
            if (deviceType && this.uuid && this.deviceToken) {
                let params = {
                    type: deviceType,
                    deviceId: this.uuid,
                    token: this.deviceToken
                };
                /*this.http.post(this.getRegisterUrl(), {...params,
                        ...options
                    }, this.httpOptions)
                    .pipe(
                        catchError((error: HttpErrorResponse) => this.handleError(error))
                    )
                    .subscribe(res => this._execCallbacks(REGISTER, res));*/
                //alert('registration URL=' + this.getRegisterUrl() + '/device/registration');
                //alert('registration params=' + JSON.stringify(params));
                //console.log('registration params = ' + JSON.stringify(params));
                this.http
                    .post(
                        this.getRegisterUrl() + '/device/registration', {
                            ...params,
                            ...options
                        },
                        this.httpOptions
                    )
                    .subscribe(
                        (data: any) => {
                            //console.log('info', JSON.stringify(data));
                            //alert("registered token " + JSON.stringify(data));
                        },
                        (error: any) => {
                            console.log(error);
                        },
                        () => {
                            //console.log("final");
                        }
                    );
            }
        }
    }
    private _setUuid(deviceID ? ) {
        if (deviceID) {
            this.uuid = deviceID;
        }
        if (!this.uuid) {
            let device = this.device;
            if (device) {
                this.uuid = device.uuid;
            }
        }
    }
    unregister() {
        this._setUuid();
        if (this.uuid) {
            this.http.delete(this.getUnregisterUrl(), this.httpOptions)
                .pipe(
                    catchError((error: HttpErrorResponse) => this.handleError(error))
                )
                .subscribe(res => {
                    console.log(this.uuid + " unregistred");
                    this.uuid = undefined;
                });
        }
    }
    update(options: any = {}) {
        this._setUuid();
        if (this.uuid) {
            this.http.put(this.getUpdateUrl(), options, this.httpOptions)
                .pipe(
                    catchError((error: HttpErrorResponse) => this.handleError(error))
                )
                .subscribe(res => {
                    this._execCallbacks(REGISTER, res)
                    console.log(this.uuid + " updated");
                });
        }
    }
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error('Backend returned code ' + error.status + ', body was: ' + error.error);
        }
        this._execCallbacks(ERROR, error);
        return throwError('Something bad happened; please try again later.');
    }
    createChannel(options: any): Promise < any > {
        return this.push.createChannel(options);
    }
    deleteChannel(id ? : string): Promise < any > {
        return this.push.deleteChannel(id);
    }
    listChannels(): Promise < any > {
        return this.push.listChannels();
    }
    setApplicationIconBadgeNumber(count) {
        if (this.pushObject) {
            return this.pushObject.setApplicationIconBadgeNumber(count);
        } else {
            return Promise.reject();
        }
    }
    getApplicationIconBadgeNumber() {
        if (this.pushObject) {
            return this.pushObject.getApplicationIconBadgeNumber();
        } else {
            return Promise.reject();
        }
    }
    clearAllNotifications() {
        if (this.pushObject) {
            return this.pushObject.clearAllNotifications();
        } else {
            return Promise.reject();
        }
    }
    getPlatform(): string {
        return this.device.platform;
    }
    getDeviceUuid(): string {
        return this.device ? this.device.uuid : "";
    }
    getPushInstance() {
            return this.pushObject;
        }
        /**
         * iOS only
         * Tells the OS that you are done processing a background push notification.
         * successHandler gets called when background push processing is successfully completed.
         * @param [id]
         */
    finish(id ? : string): Promise < any > {
        return this.pushObject.finish(id);
    }
    
};  