import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import {
    ExportedClass as TicketPipe
} from '../scripts/custom/TicketPipe';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModTicketDetails.html',
    selector: 'page-mod-ticket-details',
    styleUrls: ['ModTicketDetails.css', 'ModTicketDetails.scss']
})
export class ModTicketDetails {
    public title: string;
    public user: any;
    public mode: string;
    public phone: string;
    public card: any;
    public houses: any;
    public numbers: string;
    public strAmount: number;
    public boxAmount: number;
    public mixAmount: number;
    public isSelectedHouses: boolean;
    public item: any;
    public showWinning: boolean;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back',
            item: this.item
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public tepm: IwiModalService) {
        this.user = {};
        this.card = {};
        this.houses = {};
        this.numbers = '';
        this.isSelectedHouses = false;
        this.showWinning = true;
        this.aioChangeDetector = this.$aio_changeDetector;
        this.item = this.navParams.get('item');
        this.showWinning = this.navParams.get('showWinning');
        this.calcTotalAmount();
    }
    calcTotalAmount(): any {
        let betLinesStr = '';
        this.item.totalAmount = 0;
        this.item.totalWinAmount = 0;
        this.item.betLines.forEach((betLine) => {
            betLine.betLineStr = betLine.numbers + ' ' + betLine.houseCode + ' ' + betLine.type.substring(0, 3);
            this.item.totalAmount = this.item.totalAmount + betLine.amount;
            this.item.totalWinAmount = this.item.totalWinAmount + betLine.winningAmount;
        })
        //this.item.numberStr = this.ticketPipe.transform(this.item.number);
    }
    saleResults(data): any {
        let betLinesStr = '';
        let total = 0;
        data.betLines.forEach((betLine) => {
            betLinesStr = betLinesStr + '<br> <span>' + betLine.numbers + ' ' + betLine.houseCode + ' ' + betLine.type.substring(0, 3) + ' | ' + betLine.gameDate + ' | ' + this.iwiu.currencyPipeTransform(betLine.amount) + '</span>';
            total = total + betLine.amount;
        })
        this.iwiu.initConfirmation('Sale Ticket',
            'Ticket created successfully',
            '<br> Ticket number: ' + data.number +
            '<br>   Bet line   | Draw Date | Amount ' +
            betLinesStr +
            '<br> <br>' +
            '<br> <b> Total price: ' + this.iwiu.currencyPipeTransform(total) + '</b>',
            null, [{
                text: 'Print TBD',
                cssClass: 'secondary',
                handler: () => {
                    //no action just close alert
                }
            }, {
                text: 'Close',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    this.closeModal('update');
                }
            }])
    }
    printTicket(): any {
        this.iwi.printTicketReceipt(this.iwi.r.userData, this.item);
    }
}