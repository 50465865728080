import {
    NgModule
} from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
import {
    ExportedClass as RefreshGuard
} from './scripts/custom/RefreshGuard';
import {
    ExportedClass as PendingChangesGuard
} from './scripts/custom/PendingChangesGuard';
import {
    ExportedClass as BackButtonGuard
} from './scripts/custom/BackButtonGuard';
const routes: Routes = [{
        path: '',
        redirectTo: 'start',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () =>
            import ('./Home/Home.module').then(m => m.HomePageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'profile',
        loadChildren: () =>
            import ('./Profile/Profile.module').then(m => m.ProfilePageModule),
        canActivate: [RefreshGuard],
        canDeactivate: [PendingChangesGuard],
    },
    {
        path: 'info',
        loadChildren: () =>
            import ('./Info/Info.module').then(m => m.InfoPageModule),
    },
    {
        path: 'start',
        loadChildren: () =>
            import ('./Start/Start.module').then(m => m.StartPageModule),
        canActivate: [BackButtonGuard],
    },
    {
        path: 'login',
        loadChildren: () =>
            import ('./Login/Login.module').then(m => m.LoginPageModule),
        canActivate: [BackButtonGuard],
    },
    {
        path: 'register',
        loadChildren: () =>
            import ('./Register/Register.module').then(m => m.RegisterPageModule),
        canActivate: [BackButtonGuard],
    },
    {
        path: 'tabs',
        loadChildren: () =>
            import ('./HomeTabs/HomeTabs.module').then(m => m.HomeTabsPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'hometabs',
        loadChildren: () =>
            import ('./HomeTabs/HomeTabs.module').then(m => m.HomeTabsPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'welcome',
        loadChildren: () =>
            import ('./Welcome/Welcome.module').then(m => m.WelcomePageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'management',
        loadChildren: () =>
            import ('./Management/Management.module').then(m => m.ManagementPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'managementtabs',
        loadChildren: () =>
            import ('./ManagementTabs/ManagementTabs.module').then(m => m.ManagementTabsPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'manageclients',
        loadChildren: () =>
            import ('./ManageClients/ManageClients.module').then(m => m.ManageClientsPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'history',
        loadChildren: () =>
            import ('./History/History.module').then(m => m.HistoryPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'manageagents',
        loadChildren: () =>
            import ('./ManageAgents/ManageAgents.module').then(m => m.ManageAgentsPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'managepayout',
        loadChildren: () =>
            import ('./ManagePayout/ManagePayout.module').then(m => m.ManagePayoutPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'manageappsettings',
        loadChildren: () =>
            import ('./ManageAppSettings/ManageAppSettings.module').then(m => m.ManageAppSettingsPageModule),
        canActivate: [RefreshGuard],
    },
    {
        path: 'managebetlimits',
        loadChildren: () =>
            import ('./ManageBetLimits/ManageBetLimits.module').then(m => m.ManageBetLimitsPageModule),
    },
    {
        path: 'results',
        loadChildren: () =>
            import ('./Results/Results.module').then(m => m.ResultsPageModule),
    },
];
@NgModule({
    imports: [RouterModule.forRoot(
        routes, {
            enableTracing: false,
            useHash: true
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {}