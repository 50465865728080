import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';

import {
    Injectable
} from '@angular/core';

import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import {
    Observable,
    throwError
} from 'rxjs';
import {
    map
} from 'rxjs/operators';
import {
    IwiService
} from './IwiService';
import {
    IwiUtilService
} from './IwiUtilService';
/*
  See https://angular.io/guide/router for more info on guards.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable({
    providedIn: 'root'
})
export class BackButtonGuard implements CanActivate {
    constructor(
        private Apperyio: ApperyioHelperService,
        private router: Router,
        private iwi: IwiService,
        private iwiu: IwiUtilService
    ) {}

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {

        if (state && (state.url.indexOf('start') > -1 || state.url.indexOf('login') > -1) && this.iwi.r && !!this.iwi.r.loggedin) {
            return false;
        }

        return true;
    }

}

/*
    Guard class should be exported as ExportedClass
*/
export {
    BackButtonGuard as ExportedClass
};